/* .sidebar-gradient{
    background: rgb(206,20,128);
background: linear-gradient(180deg, rgba(206,20,128,1) 0%, rgba(215,36,115,1) 85%, rgba(223,51,105,1) 100%);
height: 600px;} */
/* .sidebar-gradient2{
    background: rgb(208,24,125);
background: linear-gradient(180deg, rgba(208,24,125,1) 0%, rgba(215,36,115,1) 100%);
} */
.logo-background{
    background-color:#ce1480;
}
.sidebar-icons,.sidebar-links{
    color: #fff;
}
.sidebar-gradient{
background: rgb(206,20,128);
background: linear-gradient(180deg, rgba(206,20,128,1) 0%, rgba(239,79,83,1) 100%);
height: 600px;
}