.input-bg{
    background-color:#fff;
    box-shadow:none;
    border:1px solid #CECECE
}
.choose::-webkit-file-upload-button {
    color: white;
    display: none;
    background: #FF4D82;
    border: none;
    padding: 7px 15px;
    font-weight: 700;
    border-radius: 3px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 10pt;
  }
