// Import main theme
@import './theme/style';

// Import css libs
@import './vendor/bootstrap/bootstrap.scss';

.ExcelTable2007 {
  border: 1px solid #b0cbef;
  border-width: 1px 0 0 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 10px;
}

.ExcelTable2007 TD {
  background-color: #fff;
  padding: 0 4px 0 2px;
  border: 1px solid #d0d7e5;
  border-width: 0 1px 1px 0;
}

.ExcelTable2007 TH {
  background-repeat: repeat-x;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #9eb6ce;
  border-width: 0 1px 1px 0;
  height: 17px;
  line-height: 17px;
  background-color: #2A7831;
}
// @import url('./fonts/Energy-Light.otf');
// @import './fonts/Energy-Bold.otf';
// @import './fonts/Energy-Regular.otf';
// @font-face {
//   font-family: 'Energy';
//   font-weight: normal;
//   font-style: normal;
//   src: local('Energy'), url(./fonts/Energy-Light.otf) format('otf');
// }

@font-face {
  font-family: 'HindSiliguri';
  font-style: normal;
  font-weight: 400;
  src:
    url('./fonts/HindSiliguri-Regular.woff') format('woff'),
    // url('../assets/font/NunitoSans-400.woff') format('woff');
}


// @font-face {
//   font-family: 'EnergyRegular';
//   font-weight: normal;
//   font-style: normal;
//   src: local('EnergyRegular'), url(./fonts/Energy-Regular.otf) format('otf');
// }

// @font-face {
//   font-family: 'EnergyBold';
//   font-weight: normal;
//   font-style: normal;
//   src: local('Energy-Bold'), url(./fonts/Energy-Bold.otf) format('otf');
// }

// @font-face {
//     font-family: 'Energy';
//     // font-weight: 900;
//     src: local('Energy'), url(./fonts/Energy-Regular.otf) format('opentype');
// }

@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';